


















import Vue from 'vue';
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator';
import simplebar from 'simplebar-vue';
import 'simplebar/dist/simplebar.min.css';
import { CheckIcon } from '@/assets/icons/heroicons/heroicons';

export interface GnStepSchema {
  id: number,
  label: string,
  path?: string | null,
}

@Component({
  components: { simplebar, CheckIcon }
})
export default class GenproxSteps extends Vue {
  @Prop({ default: false }) isLastStepCompleted: boolean;
  @Prop() currentStep: number;
  @Prop() steps: GnStepSchema[];

  getStepAdditionalClassName(stepNumber: number): string {
    if(this.currentStep > stepNumber) return 'completed';

    if(this.currentStep == stepNumber) {
      return `active ${this.isLastStepCompleted ? 'completed' : ''}`;
    }

    return '';
  }

  handleStep(step: GnStepSchema) {
    this.$emit('changeStep', step)
  }
}
